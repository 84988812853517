let preLoader = document.getElementById("page-loader") as HTMLDivElement;

window.addEventListener("load", () => {
  console.log("script kört");
  preLoader.style.display = "none";
});

/* preLoader.addEventListener("load", () => {
  console.log("script kört");
  preLoader.style.display = "none";
}); */
